//MUI
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

//Components
import stephane from "../../images/stephane.jpg";

export default function CoachesPageSmall() {
  return (
    <Box>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <CoachCard
            name="Stéphane Séguin"
            img="https://media.istockphoto.com/id/1287995227/photo/close-up-on-hand-of-unknown-caucasian-man-holding-brazilian-jiu-jitsu-bjj-black-belt-around.jpg?s=612x612&w=0&k=20&c=u-BnqSCzPzFluyx5VnUU0R9fLMIRO6S9dIzDMmEAt3Q="
            bio1="L’instructeur Stéphane Séguin, ceinture noire de jiu-jitsu
                brésilien, est affilié à HOMA BJJ par l'intermédiaire de Johnny Zemouli et
                David Coré. Il a découvert cet art martial sous la tutelle de
                Julio « Foca » Fernandez, membre de l’équipe Carlson Gracie.
                Stéphane a également participé à de nombreux tournois, dont le
                prestigieux Championnat panaméricain."
            bio2="« Mon parcours… je pratique les arts martiaux depuis plus de 25
                ans. J’ai une ceinture noire en ninjutsu; un sport qui contient
                du Ne-Waza(combat au sol), et j’ai aussi un bagage de boxe, de
                judo, de taiho-jutsu, ainsi qu’une ceinture noire en jiu jitsu
                brésilien. J’ai remporté la médaille d’or au Vermont Open BJJ
                1998 et la médaille d’or au Montgomery Open BJJ 1999 »."
          />
        </Grid>

        <Grid item xs={12}>
          <CoachCard
            name="Nicolas Borduas"
            img="https://img.olympicchannel.com/images/image/private/t_s_pog_staticContent_hero_xl_2x/f_auto/primary/w1eo4el8wr6prjicq5n4"
            bio1="L’assistant instructeur Nicolas est présentement ceinture mauve
                en jiu jitsu brésilien. Il a plus de 10 ans d'expérience en
                lutte ayant pratiqué avec les Patriotes et par la suite avec
                l'équipe Nationale de lutte olympique ce qui fait de lui un
                élément clé de notre aprentissage!"
            bio2="Il a décroché la première place au championnat canadien,
                recevant également une mention spéciale et le trophée de «
                meilleur lutteur de la compétition ». Lors d’une autre édition,
                il s’est classé deuxième. En outre, il a remporté plusieurs
                médailles d’or dans des championnats provinciaux, et ce, dans
                deux catégories distinctes."
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box
              component="img"
              src="https://media.istockphoto.com/id/1287995227/photo/close-up-on-hand-of-unknown-caucasian-man-holding-brazilian-jiu-jitsu-bjj-black-belt-around.jpg?s=612x612&w=0&k=20&c=u-BnqSCzPzFluyx5VnUU0R9fLMIRO6S9dIzDMmEAt3Q="
              sx={{
                width: "100%",
                //do not reshape image
                objectFit: "cover",
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              padding={2}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "28px", fontWeight: "bold" }}
              >
                Stéphane Séguin
              </Typography>
              <br />
              <Typography sx={{ fontSize: "21px" }}>
                L’instructeur Stéphane Séguin, ceinture noire de jiu-jitsu
                brésilien, est affilié à HOMA BJJ grâce à Johnny Zemouli et
                David Coré. Il a découvert cet art martial sous la tutelle de
                Julio « Foca » Fernandez, membre de l’équipe Carlson Gracie.
                Stéphane a également participé à de nombreux tournois, dont le
                prestigieux Championnat panaméricain.
              </Typography>
              <br />
              <Typography sx={{ fontSize: "21px" }}>
                « Mon parcours… je pratique les arts martiaux depuis plus de 25
                ans. J’ai une ceinture noire en ninjutsu; un sport qui contient
                du Ne-Waza(combat au sol), et j’ai aussi un bagage de boxe, de
                judo, de taiho-jutsu, ainsi qu’une ceinture noire en jiu jitsu
                brésilien. J’ai remporté la médaille d’or au Vermont Open BJJ
                1998 et la médaille d’or au Montgomery Open BJJ 1999 ».
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box
              component="img"
              src="https://img.olympicchannel.com/images/image/private/t_s_pog_staticContent_hero_xl_2x/f_auto/primary/w1eo4el8wr6prjicq5n4"
              width="100%"
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              padding={2}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "28px", fontWeight: "bold" }}
              >
                Nicolas Borduas
              </Typography>
              <br />
              <Typography sx={{ fontSize: "21px" }}>
                L’assistant instructeur Nicolas est présentement ceinture mauve
                en jiu jitsu brésilien. Il a plus de 10 ans d'expérience en
                lutte ayant pratiqué avec les Patriotes et par la suite avec
                l'équipe Nationale de lutte olympique ce qui fait de lui un
                élément clé de notre aprentissage!
              </Typography>
              <br />
              <Typography sx={{ fontSize: "21px" }}>
                Il a décroché la première place au championnat canadien,
                recevant également une mention spéciale et le trophée de «
                meilleur lutteur de la compétition ». Lors d’une autre édition,
                il s’est classé deuxième. En outre, il a remporté plusieurs
                médailles d’or dans des championnats provinciaux, et ce, dans
                deux catégories distinctes.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid> */}
    </Box>
  );
}

function CoachCard(props) {
  const { name, img, bio1, bio2 } = props;

  return (
    <Card>
      <CardMedia image={img} sx={{ height: 300 }} title={name} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          {bio1}
        </Typography>
        <br />
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          {bio2}
        </Typography>
      </CardContent>
    </Card>
  );
}
