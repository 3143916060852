//MUI
import { Box, Button, Typography } from "@mui/material";

//Components
import MapCustom from "../contact/MapCustom";

export default function ContactPage() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        height: "calc(100vh - 100px)",
        boxSizing: "border-box",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        padding={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="calc(100% - 4rem)"
      >
        <Typography variant="h3" textAlign="center">
          Contactez-nous!
        </Typography>
        <br />
        <Typography variant="body1" textAlign="center">
          Pour toutes questions concernant nos cours ou pour planifier un cours
          d'essai, appelez-nous au:
          <Button onClick={() => window.open("tel:5146680275")}>
            <Typography sx={{ fontWeight: "bold" }} variant="body1">
              514-668-0275
            </Typography>
          </Button>
          Ou écrivez-nous au:
          <Button
            onClick={() => {
              window.location.href = "mailto:sseguin007@live.ca";
            }}
          >
            <Typography sx={{ fontWeight: "bold" }} variant="body1">
              sseguin007@live.ca
            </Typography>
          </Button>
        </Typography>
        <br />
        <Typography variant="body1" textAlign="center">
          Nous sommes situés au 1077 Chemin de Chambly à Longueuil près du Cégep
          Édouard-Montpetit. N'hésitez pas à venir faire un tour!
        </Typography>
      </Box>
      <MapCustom />
    </Box>
  );
}
