import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import {
  AppBar,
  Box,
  Button,
  IconButton,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Instagram as InstagramIcon } from "@mui/icons-material";

//Components
import logo from "../../images/logo2.PNG";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  height: "100px",
});

function NavBarSmall() {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();

  const handleNavClick = (link) => {
    navigate(link);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setOpenMenu(false);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <StyledToolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          width="100%"
        >
          <Button
            variant="text"
            disableElevation
            sx={{
              color: theme.palette.secondary.light,
              "&:hover": {
                color: theme.palette.secondary.main,
                opacity: 0.6,
              },
            }}
            onClick={() => {
              handleNavClick("/");
            }}
          >
            <Box component="img" src={logo} height="100px" />
          </Button>

          <Box>
            <IconButton
              sx={{
                color: theme.palette.secondary.light,
                "&:hover": {
                  color: theme.palette.secondary.main,
                  cursor: "pointer",
                },
              }}
              onClick={() => window.open("https://www.instagram.com/volt.bjj")}
            >
              <InstagramIcon />
            </IconButton>

            <IconButton
              sx={{
                color: theme.palette.secondary.light,
                "&:hover": {
                  color: theme.palette.secondary.main,
                  cursor: "pointer",
                },
              }}
              onClick={() => setOpenMenu(true)}
            >
              <MenuIcon size="large" />
            </IconButton>
          </Box>
        </Box>
      </StyledToolbar>
      {openMenu && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            backgroundColor: theme.palette.primary.dark,
            width: openMenu ? "100%" : "0vw",
            transition: "all 1s ease-in-out",
          }}
        >
          <Button
            variant="text"
            disableElevation
            sx={{
              color: theme.palette.secondary.light,
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => {
              handleNavClick("/");
            }}
          >
            <Typography variant="h4">Accueil</Typography>
          </Button>
          <Button
            variant="text"
            disableElevation
            sx={{
              color: theme.palette.secondary.light,
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => {
              handleNavClick("/cours");
            }}
          >
            <Typography variant="h4">COURS</Typography>
          </Button>

          <Button
            variant="text"
            disableElevation
            sx={{
              color: theme.palette.secondary.light,
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => {
              handleNavClick("/instructeurs");
            }}
          >
            <Typography variant="h4">INSTRUCTEURS</Typography>
          </Button>
          <Button
            variant="text"
            disableElevation
            sx={{
              color: theme.palette.secondary.light,
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => {
              handleNavClick("/galerie");
            }}
          >
            <Typography variant="h4">GALERIE</Typography>
          </Button>
          <Button
            variant="text"
            disableElevation
            sx={{
              color: theme.palette.secondary.light,
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
            onClick={() => {
              handleNavClick("/contact");
            }}
          >
            <Typography variant="h4">CONTACT</Typography>
          </Button>
        </Box>
      )}
    </AppBar>
  );
}

export default NavBarSmall;
