import { useNavigate } from "react-router-dom";

//MUI
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";

//Components
import dojo from "../../images/dojo1.jpg";

export default function HomePage() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box
          component="img"
          src={dojo}
          sx={{ width: "100%", height: "100vh", objectFit: "cover" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100vh",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: "bolder",
              fontSize: "5vmin",
              color: theme.palette.primary.light,
            }}
          >
            Entrainez-vous avec nous!
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/cours");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Voir nos cours
          </Button>
        </Box>
      </Grid>

      <Grid
        item
        xs={100}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="75%"
        >
          {/*<Box position="relative" flex={1}>
                        <Box
                            component="img"
                            src="https://nationalkaratemma.com/wp-content/uploads/2018/11/istock-155282610-2.jpg"
                            sx={{ width: "100%", objectFit: "cover" }}
                        />
                    </Box>*/}
          <Box
            p={2}
            //paddingLeft={2}
            //paddingRight={2}
            // paddingBottom={2}
            //flex={2}
            //sx={{ backgroundColor: theme.palette.secondary.dark }}
          >
            <Typography variant="h3" align="center" p={2}>
              Qu'est-ce que le Jiu-Jitsu Brésilien?
            </Typography>
            <Typography variant="body1" p={2}>
              Le Jiu-Jitsu brésilien (JJB en français et BJJ en anglais) est un
              art martial brésilien dérivé du Jiu-Jitsu japonais traditionnel et
              du judo, qui se pratique principalement au sol et dont le but est
              de soumettre l’adversaire par étranglement ou clé d’articulation.
              Les frappes (coups de pied, poing, coude, tête et genou)
              volontaires sont interdites dans la pratique officielle.
              <br />
              <br />
              Le Jiu-Jitsu brésilien est un art martial moderne concentré sur la
              technique, le timing et l’effet de levier plutôt que dans la force
              brute, permettant ainsi de dominer des adversaires plus imposants.
              Comme le judo, on y retrouve des projections au sol, des
              immobilisations et des clés aux articulations.
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* <Grid item xs={100}>
                <Box display="flex">
                    <Box display="flex" flexDirection="column" flex={1}>
                        <Box>
                            <Box
                                component="img"
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Mitsuyo_Maeda_c1910.jpg/250px-Mitsuyo_Maeda_c1910.jpg"
                                alt="Mitsuyo Maéda"
                                width="100%"
                                //height="100%"
                            />
                            <Typography>Mitsuyo Maéda</Typography>
                        </Box>
                        <br />
                        <Box>
                            <Box
                                component="img"
                                src="https://cdn.shopify.com/s/files/1/0030/3742/9849/files/2_d45434b8-4f77-44e5-877c-9dfc1a45d5c1.jpg?v=1650307527"
                                alt="Helio Gracie"
                                width="100%"
                                //height="100%"
                            />
                            <Typography>Helio Gracie</Typography>
                        </Box>
                        <br />
                    </Box>

                    <Box flex={3} paddingLeft={2} paddingRight={2}>
                        <Typography variant="h4" sx={{ fontSize: "45px" }}>
                            Un peu d'histoire
                        </Typography>
                        <Typography sx={{ fontSize: "20px" }}>
                            Mitsuyo Maéda, né au Japon en 1879, pratiqua d’abord
                            le jiu-jitsu de l’école Tenshin-shinyo-ryu en 1896,
                            avant d’entrer au Kodokan (école de judo de Jigoro
                            Kano créée en 1882) en 1897. En 1904, alors 4e dan
                            de judo, il partit aux États-Unis d’Amérique, avec
                            Tsunéjiro Tomita, pour faire la démonstration de
                            cette nouvelle discipline en affrontant des
                            lutteurs. Il effectua un premier séjour au Brésil en
                            1907, puis en 1914 (à Belem), et participa à
                            l’établissement de la colonie japonaise au Brésil en
                            1920.Au cours de ces séjours au Brésil, il participa
                            à des combats de lutte libre (vale-tudo) pour
                            montrer la supériorité du Kodokan (école de judo
                            ju-jitsu japonais) et pour gagner un peu d’argent.
                            Il fut alors surnommé « Conde Koma » ou le « comte
                            des combats ». Durant cette période, il fut aidé par
                            Gastao Gracie, descendant d’une famille écossaise.
                            En remerciement, il enseigna l’art du combat à son
                            fils, Carlos Gracie, qui l’enseigna à son tour à ses
                            frères, Oswaldo, Gastao Jr, George Jr (Gastao et
                            Cesalina Gracie avaient huit fils).
                            <br />
                            <br />
                            Devenu leader du clan Gracie, le jeune élève Carlos
                            qui était attiré par le combat réel modifia
                            rapidement les techniques apprises par le comte Koma
                            pour l’adapter à la réalité brésilienne. Carlos
                            Gracie commença à tester lui-même ses techniques en
                            lançant lui aussi des défis. Carlos Gracie avait la
                            réputation de combattre n’importe qui, sans
                            distinction de taille ou de poids. Il demeura
                            invaincu et devint une légende au Brésil. Helio
                            Gracie était un autre frère de Carlos, mais le
                            médecin lui avait déconseillé de pratiquer, en
                            raison de sa santé fragile et il se contentait donc
                            de regarder et d’écouter l’enseignement de son
                            frère. À l’âge de 16 ans, il remplaça Carlos lors
                            d’un cours, en se servant de ce qu’il avait
                            mémorisé. Il commença ainsi la pratique du ju-jitsu,
                            mais repensa le ju-jitsu traditionnel avec l’esprit
                            brésilien et adapta les mouvement à sa propre
                            physionomie, étant de petit gabarit (1m65, 64 kg).
                            Il créa ainsi un art martial propre, qui reste jeune
                            par rapport à son ancêtre japonais.
                            <br />
                            <br />
                            Une première tentative d’imposer cet art martial
                            face à l’école japonaise échoua ainsi en 1951, avec
                            la défaite d’Helio Gracie face au champion du Japon
                            de Judo, Masahiko Kimura. Celui-ci récidivait 8 ans
                            plus tard en venant à bout de Santana, champion de
                            Gracie jiu-Jitsu (1 victoire et 1 nul). Pour
                            promouvoir le jiu-jitsu brésilien, Rorion Gracie,
                            fils d’Helio crée le célèbre Ultimate Fighting
                            Championship. Avant de devenir la compétition
                            réglementée qu’elle est aujourd’hui (limite de
                            temps, catégorie de poids, test-anti dopage, etc.),
                            l’UFC a d’abord été pensé pour se rapprocher d’un
                            combat réel, un tournoi tous styles confondus et
                            sans distinction de poids dans le but de trouver le
                            combattant ultime. Entouré de plusieurs champions
                            d’arts martiaux de pieds/poings (boxe, karate,
                            kickboxing, etc.), Royce Gracie, fils de Helio et
                            représentant du Jiu-Jitsu brésilien fait office
                            d’outsider lors du premier opus. Pourtant, il finit
                            par remporter le tournoi, marquant ainsi l’avènement
                            du jujitsu brésilien. Royce gagnera aussi les 2è et
                            4è éditions. Le jiujitsu brésilien, art martial
                            encore méconnu aux États-Unis, va prendre son essor.
                            Et avec les victoires de Royce aux 2è et 4è éditions
                            de l’UFC, le JJB ne cessera de se populariser. A la
                            même période, le légendaire Rickson Gracie fait écho
                            aux victoires de son demi-frère Royce en remportant
                            les Vale Tudo au Japon, une autre compétition de
                            combat libre.
                            <br />
                            <br />
                            Aujourd’hui, des Académies de jiu-jitsu brésilien se
                            sont créées un peu partout, chaque membre de la
                            famille Gracie dirigeant sa propre académie (ou
                            presque), même si le nombre de pratiquants reste
                            restreint en Europe. En plus de démontrer la
                            supériorité du jujitsu brésilien sur les autres arts
                            martiaux, l’UFC et Royce Gracie ont permis de
                            révolutionner la pratique des arts martiaux Gi / No-gis
                            en introduisant le combat au sol. Aujourd’hui, tous
                            les combattants de l’UFC et autres compétitions de
                            combat libre sont adeptes de cross-training : ils
                            pratiquent aussi bien un art martial de frappe qu’un
                            art de grappling comme le JJB. Le Jiu-jitsu
                            brésilien est d’ailleurs le sport de combat au sol
                            le plus représenté chez les athlètes modernes de
                            MMA.
                        </Typography>
                    </Box>
                </Box>
            </Grid>*/}
    </Grid>
  );
}
