//MUI
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
} from "@mui/material";

//Components
import { useTheme } from "@mui/material";

import cours from "../../images/cours/cours1.jpg";

export default function ClassesPageSmall() {
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${cours})`,
          backgroundPosition: "bottom ",
          minHeight: "calc(100vh - 100px)",
          backgroundSize: " cover",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: theme.palette.primary.light,
          }}
        >
          NOS COURS
        </Typography>
      </Box>
      <Card
        sx={{
          width: "100%",
          height: "fit-content",
          marginRight: 2,

          backgroundColor: theme.palette.primary.light,
        }}
      >
        <CardContent>
          <Typography variant="body1">
            Notre programme vise premièrement à ce que nos élèves maitrises les
            bases du Jiu-Jitsu. Nous croyons fortement en l'efficacité de
            techniques simples, mais prouvées comme étant efficaces. Il est
            aussi incorporé au programme des techniques plus modernes telles que
            le berimbolo et la matrix, ainsi que de la lutte. Nos cours couvrent
            les projections au sol, ainsi que le combat au sol. Les cours
            suivent un programme de 12 mois permettant de perfectionner les
            différentes positions et techniques.
          </Typography>
          <br />

          <Typography variant="body1">
            Les frais d'inscription sont de 390$ pour 3 mois pour les adultes et
            de 315$ pour 3 mois pour les femmes et les adolescents (12 ans à 17
            ans). Ceci inclus aussi un accès illimité aux cours chez{" "}
            <Link
              href="https://www.homabjj.com/horaire.html"
              target="_blank"
              color="rgb(0, 0, 255)"
            >
              HOMA BJJ
            </Link>
            .
          </Typography>
          <br />

          <Typography variant="body1">
            Contactez-nous pour les informations et pour planifier un cours
            d'essai!
          </Typography>
        </CardContent>
      </Card>

      <Grid container xs={12} width="100%" mr={2}>
        {weekDays.map((day) => (
          <WeekDay key={day} day={day} />
        ))}
      </Grid>
    </Box>
  );
}

const weekDays = [
  {
    label: "Lundi",
    hours: "19h30 - 21h00",
    type: "Gi",
  },
  {
    label: "Mardi",
    hours: "Aucun cours",
    type: null,
  },
  {
    label: "Mercredi",
    hours: "19h30 - 21h00",
    type: "Gi / No-gi",
  },
  {
    label: "Jeudi",
    hours: "Aucun cours",
    type: null,
  },
  {
    label: "Vendredi",
    hours: "19h30 - 21h00",
    type: "Gi / No-gi (libre)",
  },
  {
    label: "Samedi",
    hours: "Aucun cours",
    type: null,
  },
  {
    label: "Dimanche",
    hours: "14h00 - 15h30",
    type: "Gi",
  },
];

function WeekDay({ day }) {
  const theme = useTheme();

  return (
    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "125px",
          backgroundColor: day.label
            ? theme.palette.secondary.light
            : theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.dark}`,
          p: 2,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
          {day.label}
        </Typography>
        <Typography variant="body1">{day.hours}</Typography>
        <Typography
          variant="body1"
          sx={{
            color: day.type ? "black" : "transparent",
          }}
        >{`${day.type}`}</Typography>
      </Box>
    </Grid>
  );
}
